$(function(){
    var logoSearchCart = $('.sticky-header'),
        header = $('.page-header'),
        h = logoSearchCart.height(),
        win = $(window);

    if(win.width() >= 992){
        // Fix the logo-search-cart div on top when scrolling down.
        var show_fixed = false;
        win.on("scroll touchmove", function () {
            var show_fixed_new = $(document).scrollTop() > 90;
            if(show_fixed != show_fixed_new){
                show_fixed = show_fixed_new;
                logoSearchCart.toggleClass("sticky-header--stuck", show_fixed);
                header.css('margin-top', show_fixed ? h + 'px' : 0);
            }
        });
    }

    // Disable the dropdown-toggle on category menu items on large screens.
    $('.header-menu-mega .nav-link').on('click', function(e) {
        if (win.width() >= 992) {
            e.stopPropagation();
        }
    });
})
