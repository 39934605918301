"use strict";

import "regulus-oscar";
import "./components/sticky-header";
import "./components/aspira";
import "./components/carousel-catalogue-filter-form";

$(function () {
    const accordion = $('#accordion');
    accordion.on('hidden.bs.collapse', function () {
        $('#accordion #see-more')
            .removeClass("d-none");
        $('#accordion #see-less')
            .addClass("d-none");
    });
    accordion.on('shown.bs.collapse', function () {
        $('#accordion #see-more')
            .addClass("d-none");
        $('#accordion #see-less')
            .removeClass("d-none");
    });

    const toggleAccordionVisibility = function () {
        const productLongDescContent = $(".product-long-desc__content");
        const content = productLongDescContent.html();
        const trimedContent = $.trim(content);

        if (trimedContent === "") {
            $("#accordion").addClass("d-none");
        } else {
            $("#accordion").removeClass("d-none");
        }
    }
    toggleAccordionVisibility();
});

//sliders
$(function () {
    $('.slider').each(function (_, element) {
      const slider = $(element);
      const sliders = slider.find('.slider-container');
      slider.find('.js-slider-previous').click(function () {
          for (let i = 0; i < sliders.length; i++) {
              sliders[i].scrollBy({
                top: 0,
                left: -275,
                behavior: 'smooth'
              });
          }
      });
      slider.find('.js-slider-next').click(function () {
          for (let i = 0; i < sliders.length; i++) {
              sliders[i].scrollBy({
                top: 0,
                left: 275,
                behavior: 'smooth'
              });
          }
      });
    });
});

