"use strict";

$(".aspira-modal").on("show.bs.modal", function() {
  const $modalBody = $(".aspira-modal .modal-body");
  if ($modalBody.html() === "") {
    const $spinner = $(".aspira-modal .spinner-border"),
      iframe = document.createElement("iframe");
    iframe.src = "https://aspira.co.ke/aspira-product-financing-widget/";
    iframe.addEventListener("load", function() {
      $spinner.addClass("d-none");
    });
    $modalBody.html(iframe);
    $spinner.removeClass("d-none");
  }
});
